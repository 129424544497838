import React from "react"
import { graphql } from "gatsby";
import { Container, Box, Grid } from "@material-ui/core";

import { makeStyles } from '@material-ui/core/styles';
import CollapseIcon from '../components/CollapseIcon';

import Layout from "../components/layout"
import SEO from "../components/seo"
import { PageTitle } from '../components/PageTitle'
import { Document } from "../styles/pages/documentos";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function DocumentsPage(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  const cpa = props.data.cpa.edges

  return (
    <Layout>
      <SEO title="CPA" />
      <Container style={{ marginTop: 50, minHeight: '50vh'}}>
        <PageTitle>CPA</PageTitle>
        <Grid container>
          <Grid xs={12} sm={12}>
            <CollapseIcon
              head="CPA"
              icon="fa fa-folder"
              items={cpa.map(({ node }) => ({
                "name": node.name, "to": node.publicURL, "type": "external", "icon": "fa fa-link"
              }))}
            />
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}

export const pagequery = graphql`
  query {
    cpa: allFile(filter: {relativeDirectory: {eq: "CPA"}}) {
      edges {
        node {
          name
          publicURL
        }
      }
    }
  }
`



